import React from "react";
import thankyou from '../image/thankyou.png'
import { Link } from "react-router-dom";
import './Softwarehouse.css'
export default function ThankYou() {
  return (
    <div className="lyt-main  flex is-y align-middle justify-center">
      <img className="img-thnk-u" src={thankyou} />
      <h3 className="thank-h" style={{ marginTop:"2%"}}>
        Thank you,enjoy!
      </h3>
      <p className="s-thank-txt" style={{ marginBottom:"20px"}}>We have received your query, our representative will get back to you</p>
      <Link to="/" className="btn is-sld is-primary">
        Go back to home
      </Link>
      <p style={{ marginTop:"5%"}}>if you have any issue <Link to="/contact-us">contact us</Link></p>
    </div>
  );
}
