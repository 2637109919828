import React, { useState, useEffect } from "react";
import './WhatsAppChat.css'
import whatsapp from '../../image/home/whatsapp.png'
import { Link } from "react-router-dom";

const ScrollUp: React.FC = () => {




 


  return (
    <>
    
      <div className="whatsapp-bot">
        <a href="https://wa.me/03338538337" target="_blank" rel="noopener noreferrer">
          <img src={whatsapp} className="whatsapp-btn" />
        </a>
      </div>

      <div className="free-const">
      <a href='https://calendly.com/quellxcode/30min?month=2023-03'  className="btn is-sld is-primary is-pill font-bold mr-1rem flex move-icon" style={{ padding: "10px 30px" }}>
      Free Consultation
          <i className="icon ri-arrow-right-line ml-7px" />
        </a>
      </div>
    </>
  );
};

export default ScrollUp;
