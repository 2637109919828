import React, { useRef } from "react";
import VisibilitySensor from "react-visibility-sensor";
import { Helmet } from "react-helmet";
import './Vogue.css'
import img1 from '../../image/casestudy/vogue/img1.png'
import img2 from '../../image/casestudy/vogue/img2.png'
import SEO from '../../image/casestudy/vogue/SEO.png'
import Adwords from '../../image/casestudy/vogue/Adwords.png'
import Social from '../../image/casestudy/vogue/Social.png'
import graph from '../../image/casestudy/vogue/graph.png'

import img3 from '../../image/casestudy/vogue/img3.jpg'
import img4 from '../../image/casestudy/vogue/img4.png'
import img5 from '../../image/casestudy/vogue/img5.png'
import img6 from '../../image/casestudy/vogue/img6.png'
import img7 from '../../image/casestudy/vogue/img7.png'
import img8 from '../../image/casestudy/vogue/img8.png'








import imgLogo from '../../image/casestudy/vogue/imgLogo.png'

export default function Vogue() {
  const body = useRef(null);

  function setbodycolor(primary: boolean, classes: string[]) {
    let e = (body.current as any) as HTMLElement;
    primary ? classes.map((c) => e.classList.add(c)) : classes.map((c) => e.classList.remove(c));
  }

  return (
    <>
      <Helmet>
        <title>Vogue | Branding</title>
        <meta name="description" content="Branding Design for a Cosmetics Company" />
      </Helmet>

      <div ref={body} className="clr-white bg-primary main-vogue">
      <VisibilitySensor partialVisibility offset={{ top: window.innerHeight / .9 }} delayedCall onChange={(visible) => setbodycolor(visible, ["bg-primary","clr-green"])}>
          <section className="pb-6 pt-10 px-6 h-min-100vh grid align-bottom ">
     <div className="vogue-flex">

        <div className="vogue-flex-left">
<h6>Case Study</h6>
<h1>VOGUE ESTHETIC</h1>
<h3>Industry:</h3>
<h4>Skin Care</h4>
<h3>Solution:</h3>
<h4>Social Media, Seo,Ads management</h4>

        </div>
        <div className="vogue-flex-right">

            <img src={img1}/>
        </div>
     </div>

     <div className="vogue-flex2">

<div className="vogue-flex2-left">
<div className="vogue-flex2-left-grid">
<div className="vogue-flex2-left-grid-item">

  <h3>90%</h3>
  <p>Increase in site traffic<br/>(2023-2014 Vs 2022)</p>
</div>
<div className="vogue-flex2-left-grid-item">

  <h3>80%</h3>
  <p>Increase in Lead Generation<br/>(2023-2024 Vs 2022)</p>
</div>
<div className="vogue-flex2-left-grid-item">

  <h3>190%</h3>
  <p>Increase in fb Page Followers<br/>(2023-2024 Vs 2022)</p>
</div>

</div>


</div>
<div className="vogue-flex2-right">

    <img src={graph}/>
</div>
</div>

<div className="vogue-flex" style={{marginTop:"10%"}}>

<div className="vogue-flex-left">
<h6>THE CLIENT</h6>
<h1>Esthetics Clinic</h1>
<p>Vogue Aesthetic Clinic in Bahria Town Phase 4, Rawalpindi. Established in
July 2023, we specialize in advanced aesthetic treatments tailored to
individual goals, from non-invasive procedures like HIFU to rejuvenating
therapies like chemical peels. With state-of-the-art technologies and an
expert team, we offer services including Laser hair removal, Botox, Fillers,
and more, empowering individuals to embrace their beauty confidently..</p>
<p>Prioritizing patient-centric approaches, Vogue Esthetics offers tailored\
treatment plans to address diverse needs and goals. Emphasizing
preventive skincare, fostering a welcoming environment, and consistently
delivering exceptional service are core goals for the clinic. As a dedicated
skincare provider, vogue esthetics is committed to enhancing the overall
well-being of the community it serves.</p>

</div>
<div className="vogue-flex-right">

    <img src={imgLogo}/>
</div>
</div>





          </section>
        </VisibilitySensor>
        </div>

      <div className="vogue-problem">
        <h5>THE PROBLEM</h5>
        <h3>Weak Online Positioning</h3>
        <p>As they embarked on their journey, our client was eager to exhibit his work
online. Yet, the absence of a digital footprint presented him with
multifaceted hurdles to overcome.</p>
        </div>  


 {/* sec 3 */}
 <div className="vogue-flex-third px-6 pb-6 pt-6" >
 <div className="vogue-flex-third-left">

<img src={img2}/>
</div>
<div className="vogue-flex-third-right">
<h6>THE SOLUTION</h6>
<h1>Improve, Target, Convert</h1>
<p>Quellxcode helped them to solve all their problems, enhance their brand
visibility, gave audience engagement and high conversion rate with the help
of proper Social Media Marketing Optimization, Content Marketing and
Performance Marketing.<br/><br/>In short, We implemented the following actions:</p>

<div className="flex-everpure-gplus">
<div className="flex-everpure-gplus-left">

<img src={SEO}/>
<h3>
SEO</h3>
</div>
<div className="flex-everpure-gplus-left">

<img src={Adwords}/>
<h3>Google<br/>
Adwords</h3>
</div>
<div className="flex-everpure-gplus-left">

<img src={Social}/>
<h3>Social Media
</h3>
</div>

</div>

</div>

</div>

 {/* sec 4 */}
 <div className="vogue-problem">
        <h5>OUR APPROACH</h5>
        <h3>Social Media Design</h3>
        <p>We enhanced Vogue Esthetics - The skin care Clinic's digital presence by creating engaging User
Geneaed Content (UGC) in the form of trending reels featuring patients. Leveraging the popularity of
dynamic video content, our strategy resulted in significantly improved reach compared to static
graphical posts, ensuring a visually appealing and consistent online presence for the clinic.</p>
        </div>  

  {/* sec 5 */}
  <img src={img3} style={{width:"100%"}}/>

  {/* sec 6 */}
  <div className="vogue-flex-third px-6 pb-6 pt-6" >
 <div className="vogue-flex-third-left">

<img src={img4}/>
</div>
<div className="vogue-flex-third-right">
<h6>THE APPROACH</h6>
<h1>Social Media Management</h1>
<p>We enhanced Vogue Esthetics - The Skincare Clinic's digital
presence by creating engaging User Generated Content (UGC) in
theform of trending reels featuring patients. Leveraging the
popularity of dynamic video content, our strategy resulted in
significantly improved reach compared to static graphical posts,
ensuring a visually appealing and consistent online presence for
the clinic.</p>



</div>

</div>
  {/* sec 7 */}
  <div className="vogue-problem">
        <h5>OUR APPROACH</h5>
        <h3>Meta Ads</h3>
        <p>We made the strategy in such a way that they can get more visitors & get maximum
benefits from Facebook Paid Ads, We created an audience like</p>
        </div>  
 

 

  
     
         {/* sec 12 */}
        <div className="appoach-img-sec-vogue">

        <div className="vogue-problem-right">

        <div className="vogue-problem" style={{color:"white" ,padding:"0"}}>
        <h5>OUR APPROACH</h5>
        <h3>Meta Ads</h3>
        
        </div>  
<img  src={img5} style={{margin:"4% 0 3% 0"}}/>
<img  src={img6}/>
</div>
        </div>
 
   {/* sec 13 */}
    <div className="everp-performance" style={{color:"black"}}>
<h3>Performance:</h3>
<div className="everp-performance-grid">
<div className="performance-grid-child">
  <h4>90%</h4>
  <p>Increase in site traffic<br/>
  (2023-2024 Vs 2022)


  </p>
</div>
<div className="performance-grid-child">
  <h4>180%</h4>
  <p>Increase in Lead Generation<br/>
  (2023-2024 Vs 2022)


  </p>
</div>
<div className="performance-grid-child">
  <h4>190%</h4>
  <p>Increase in fb Page Followers<br/>
  (2023-2024 Vs 2022)


  </p>
</div>
<div className="performance-grid-child">
  <h4>250%</h4>
  <p>Increase in reach<br/>
  (2023-2024 Vs 2022)


  </p>
</div>
<div className="performance-grid-child">
  <h4>300%</h4>
  <p>Increase in likes<br/>
  (2023-2024 Vs 2022)


  </p>
</div>
<div className="performance-grid-child">
  <h4>340%</h4>
  <p>Increase in engagement<br/>
  (2023-2024 Vs 2022)


  </p>
</div>
<div className="performance-grid-child">
  <h4>450%</h4>
  <p>Increase in ROAS<br/>
  (2023-2024 Vs 2022)


  </p>
</div>
<div className="performance-grid-child">
  <h4>590%</h4>
  <p>Increase in site impressions<br/>
  (2023-2024 Vs 2022)


  </p>
</div>
</div>

    </div>
       {/* sec 13 */}
       <div className="appoach-img-sec-vogue">

<div className="vogue-problem-right">
<div className="vogue-problem" style={{color:"white" ,padding:"0"}}>
        <h5>OUTCOME</h5>
        <h3>GOALS ACHEIVED</h3>
        <p>We have provided a 200% lead improvement by survey campaign,
and also provided improvement in leads. The client got conversions
from FB leads</p>
        
        </div>  

<img  src={img7} style={{margin:"4% 0 4% 0"}}/>
<img  src={img8}/>
</div>
</div>
    </>
  );
}
