import React, { useRef } from "react";
import VisibilitySensor from "react-visibility-sensor";
import { Helmet } from "react-helmet";
import './EverPure.css'
import img1 from '../../image/casestudy/everpure/img1.png'
import img2 from '../../image/casestudy/everpure/img2.png'
import img3 from '../../image/casestudy/everpure/img3.jpg'
import img4 from '../../image/casestudy/everpure/img4.jpg'
import img5 from '../../image/casestudy/everpure/img5.jpg'
import img6 from '../../image/casestudy/everpure/img6.jpg'
import img7 from '../../image/casestudy/everpure/img7.png'
import img8 from '../../image/casestudy/everpure/img8.jpg'
import img9 from '../../image/casestudy/everpure/img9.jpg'
import img10 from '../../image/casestudy/everpure/img10.png'
import img11 from '../../image/casestudy/everpure/img11.png'
import img12 from '../../image/casestudy/everpure/img12.png'
import img13 from '../../image/casestudy/everpure/img13.png'
import img14 from '../../image/casestudy/everpure/img14.png'
import img15 from '../../image/casestudy/everpure/img15.png'
import img16 from '../../image/casestudy/everpure/img16.png'

import grid1 from '../../image/casestudy/everpure/grid1.jpg'
import grid2 from '../../image/casestudy/everpure/grid2.jpg'
import grid3 from '../../image/casestudy/everpure/grid3.jpg'
import grid4 from '../../image/casestudy/everpure/grid4.jpg'
import grid5 from '../../image/casestudy/everpure/grid5.jpg'
import grid6 from '../../image/casestudy/everpure/grid6.jpg'
import section2 from '../../image/casestudy/everpure/section2.jpg'





export default function EverPure() {
  const body = useRef(null);

  function setbodycolor(primary: boolean, classes: string[]) {
    let e = (body.current as any) as HTMLElement;
    primary ? classes.map((c) => e.classList.add(c)) : classes.map((c) => e.classList.remove(c));
  }

  return (
    <>
      <Helmet>
        <title>EverPure | Branding</title>
        <meta name="description" content="Branding Design for a Cosmetics Company" />
      </Helmet>

      <div ref={body} className="clr-green bg-primary main-everpure">
      <VisibilitySensor partialVisibility offset={{ top: window.innerHeight / .9 }} delayedCall onChange={(visible) => setbodycolor(visible, ["bg-primary","clr-green"])}>
          <section className="pb-6 pt-10 px-6 h-min-100vh grid align-bottom ">
     <div className="ep1-flex">

        <div className="ep1-flex-left">
<h6>Case Study</h6>
<h1>EverPure</h1>
<h3>Industry:</h3>
<h4>Skin Care Products</h4>
<h3>Solution:</h3>
<h4>Social Media, Seo,Ads management</h4>

        </div>
        <div className="ep1-flex-right">

            <img src={img1}/>
        </div>
     </div>

     <div className="ep1-flex">

<div className="ep1-flex-left">
<p>THE CLIENT</p>
<h3>Selfcare Products</h3>
<p>Introducing EverPure, a pioneering self-care brand founded in August 2023.
With a focus on skincare and haircare, EverPure's online store offers a meticulously
curated collection of products designed to rejuvenate and nourish.
Serving customers across Pakistan, EverPure's mission is to promote holistic
well-being and empower individuals to prioritize self-care. Committed to
quality and accessibility, EverPure ensures seamless delivery services
nationwide, making self-care accessible to all.</p>


</div>
<div className="ep1-flex-right">

    <img src={img2}/>
</div>
</div>





          </section>
        </VisibilitySensor>
        </div>

        <img src={img14} style={{width:"100%"}}/>
    {/* grid section */}
        <div className="everpure-grid-sec">

<div className="grid-everpure grid1">

  <img src={grid1}/>
</div>
<div className="grid-everpure grid2"></div>
<div className="grid-everpure grid3">

<img src={grid2}/>
</div>
<div className="grid-everpure grid4"></div>
<div className="grid-everpure grid5"></div>
<div className="grid-everpure grid6">

<img src={grid3}/>
</div>
<div className="grid-everpure grid7"></div>
<div className="grid-everpure grid8">

<img src={grid4}/>
</div>
<div className="grid-everpure grid9">

<img src={grid5}/>
</div>
<div className="grid-everpure grid10"></div>
<div className="grid-everpure grid11">

<img src={grid6}/>
</div>
<div className="grid-everpure grid12"></div>
</div>

 {/* sec 3 */}
     
<div className="everpure-problem-main">

  <img src={img3}/>
  <div className="everpure-problem-right">

    <h5>THE PROBLEM</h5>
    <h3>Weak Online Positioning</h3>
    <p>As they embarked on their journey, our client was eager to exhibit his
work online. Yet, the absence of a digital footprint presented him with
multifaceted hurdles to overcome.</p>
  </div>
</div>
 {/* sec 4 */}
 <img src={section2} style={{width:"100%"}} />

  {/* sec 5 */}

  <div className="everpure-problem-main everpure-reverse-flex">

  <img src={img4}/>
  <div className="everpure-problem-right">

    <h5>THE SOLUTION</h5>
    <h3>Improve, Target, Convert</h3>
    <p>Quellxcode helped them to solve all their problems, enhance their brand
visibility, gave audience engagement and high conversion rate with the help
of proper Social Media Marketing Optimization, Content Marketing and
Performance Marketing.<br/><br/>In short, We implemented the following actions:</p>
<div className="flex-everpure-gplus">
<div className="flex-everpure-gplus-left">

<img src={img15}/>
<h3>Google<br/>
Adwords</h3>
</div>
<div className="flex-everpure-gplus-right">

<img src={img16}/>
<h3>Social Media
</h3>
</div>

</div>
  </div>
</div>
  {/* sec 6 */}
  <img src={img5} style={{width:"100%"}}/>
  {/* sec 7 */}
  <div className="everpure-problem-main">

  <img src={img6}/>
  <div className="everpure-problem-right">

    <h5>OUR APPROACH</h5>
    <h3>Social Media Design</h3>
    <p>We enhanced Vogue Esthetics - The skin care Clinic's digital presence
by creating engaging User Geneaed Content (UGC) in the form of
trending reels featuring patients. Leveraging the popularity of
dynamic video content, our strategy resulted in significantly
improved reach compared to static graphical posts, ensuring a
visually appealing and consistent online presence for the clinic.</p>
  </div>
</div>
  {/* sec 8 */}
  <img className="img-minus-margin-everpure" src={img7} />
    {/* sec 9 */}

    <div className="everpure-problem-main everpure-reverse-flex">

<img  src={img8}/>
<div className="everpure-problem-right">

  <h5>OUR APPROACH</h5>
  <h3>Social Media Management</h3>
  <p>We enhanced Everpure - The skin care products digital presence
by creating engaging User Geneaed Content (UGC) in the form of
trending reels featuring patients. Leveraging the popularity of
dynamic video content, our strategy resulted in significantly
improved reach compared to static graphical posts, ensuring a
visually appealing and consistent online presence for the Product.</p>
</div>
</div>

    {/* sec 10 */}
    <img  src={img9} style={{width:"100%"}}/>
        {/* sec 11 */}
        <div className="everp-approach">
        <div className="everpure-problem-right">

<h5>OUR APPROACH</h5>
<h3>Meta Ads</h3>
<p>We made the strategy in such a way that they can get more visitors & get maximum
benefits from Facebook Paid Ads, We created an audience like</p>
</div>
        </div>
         {/* sec 12 */}
        <div className="appoach-img-sec-main">

        <div className="everpure-problem-right">

<h5>OUR APPROACH</h5>
<h3>Meta Ads</h3>
<img  src={img10} style={{margin:"4% 0 8% 0"}}/>
<img  src={img11}/>
</div>
        </div>
 
   {/* sec 13 */}
    <div className="everp-performance">
<h3>Performance:</h3>
<div className="everp-performance-grid">
<div className="performance-grid-child">
  <h4>100%</h4>
  <p>Increase in site traffic<br/>
  (2023-2024 Vs 2022)


  </p>
</div>
<div className="performance-grid-child">
  <h4>190%</h4>
  <p>Increase in Sale Generation<br/>
  (2023-2024 Vs 2022)


  </p>
</div>
<div className="performance-grid-child">
  <h4>80%</h4>
  <p>Increase in fb Page Followers<br/>
  (2023-2024 Vs 2022)


  </p>
</div>
<div className="performance-grid-child">
  <h4>250%</h4>
  <p>Increase in reach<br/>
  (2023-2024 Vs 2022)


  </p>
</div>
<div className="performance-grid-child">
  <h4>300%</h4>
  <p>Increase in likes<br/>
  (2023-2024 Vs 2022)


  </p>
</div>
<div className="performance-grid-child">
  <h4>350%</h4>
  <p>Increase in engagement<br/>
  (2023-2024 Vs 2022)


  </p>
</div>
<div className="performance-grid-child">
  <h4>450%</h4>
  <p>Increase in ROAS<br/>
  (2023-2024 Vs 2022)


  </p>
</div>
<div className="performance-grid-child">
  <h4>400%</h4>
  <p>Increase in site impressions<br/>
  (2023-2024 Vs 2022)


  </p>
</div>
</div>

    </div>
       {/* sec 13 */}
       <div className="appoach-img-sec-main">

<div className="everpure-problem-right">

<h5>OUTCOME</h5>
<h3>GOALS ACHEIVED</h3>
<p>We have provided a 200% sales improvement by sales campaign,
The client got conversions from FB leads.</p>
<img  src={img12} style={{margin:"4% 0 8% 0"}}/>
<img  src={img13}/>
</div>
</div>
    </>
  );
}
