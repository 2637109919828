import React, { useEffect } from "react";
import thankyou from '../image/thankyou.png';
import { Link, useHistory } from "react-router-dom";
import './Softwarehouse.css';

export default function Success() {
  const history = useHistory();

  useEffect(() => {
    const redirectTimeout = setTimeout(() => {
      history.push("/");
    }, 5000); 

    return () => {
      clearTimeout(redirectTimeout); 
    };
  }, [history]);

  return (
    <div className="lyt-main  flex is-y align-middle justify-center">
      <img className="img-thnk-u" src={thankyou} alt="Thank You" />
      <h3 className="thank-h" style={{ marginTop: "2%" }}>
        Thank you for Subscription
      </h3>
     
      <Link to="/" className="btn is-sld is-primary">
        Go back to home
      </Link>
      <p style={{ marginTop: "5%" }}>if you have any issue <Link to="/contact-us">contact us</Link></p>
    </div>
  );
}
